import accessibleAutocomplete from 'accessible-autocomplete';

const portChoiceSelector = Array.from(
  document.getElementsByClassName('port-choice-selector'),
);

document.addEventListener('DOMContentLoaded', () => {
  portChoiceSelector.forEach((selectElement) => {
    accessibleAutocomplete.enhanceSelectElement({
      selectElement,
      minLength: 2,
      inputClasses: 'govuk-input govuk-input--width-4',
      menuClasses: 'autocomplete-gds-font govuk-input--width-5',
      required: true,
      autocomplete: true,
    });
  });
});
