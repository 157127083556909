import accessibleAutocomplete from 'accessible-autocomplete';

const fullNameSelector = Array.from(
  document.getElementsByClassName('full-name-selector'),
);

document.addEventListener('DOMContentLoaded', () => {
  fullNameSelector.forEach((selectElement) => {
    accessibleAutocomplete.enhanceSelectElement({
      selectElement,
      minLength: 2,
    });
  });
});
