/** remove the whitespaces from email field */
const emailsArr = ['email', 'userEmailAddress'];

emailsArr.map((ids) => {
  if (document.getElementById(ids)) {
    document.getElementById(ids).addEventListener('change', (e) => {
      e.target.value = e.target.value.trim();
    });
  }
});
