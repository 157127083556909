document.addEventListener('DOMContentLoaded', () => {
  const pollingInterval = 2000;
  const loadingScreen = document.getElementById('checkin-loading-screen');
  const bodyElement = document.body;
  let pollIntervalId;

  async function pollAndUpdateDOM() {
    try {
      const checkinResponse = await fetch('/sarfile/checkin?poll');
      const progress = await checkinResponse.json();

      if (progress.progress !== 'Incomplete') {
        location.reload();
        clearInterval(pollIntervalId);
      }
    } catch {
      bodyElement.innerHTML =
        '<p>Could not load data. Please try again later</p>';
    }
  }

  function manageScrollPosition() {
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('scrollPosition', window.scrollY);
    });

    window.addEventListener('load', () => {
      const scrollPosition = localStorage.getItem('scrollPosition');
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition, 10));
      }
    });
  }

  if (loadingScreen) {
    manageScrollPosition();
    pollIntervalId = setInterval(pollAndUpdateDOM, pollingInterval);
  }
});
