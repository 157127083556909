import accessibleAutocomplete from 'accessible-autocomplete';

const countryCodeSelector = Array.from(
  document.getElementsByClassName('country-code-selector'),
);

document.addEventListener('DOMContentLoaded', () => {
  countryCodeSelector.forEach((selectElement) => {
    accessibleAutocomplete.enhanceSelectElement({
      selectElement,
      showAllValues: true,
      dropdownArrow: () => '',
    });
  });
});
