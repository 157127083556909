/** The code below is used for Arrival Details page , Departure Details page, Person details page */

const dateFieldsArr = [
  'dateOfBirth',
  'documentExpiryDate',
  'arrivalDate',
  'departureDate',
];

dateFieldsArr.map((dateField, i) => {
  const dateVar = document.querySelectorAll(`.${dateField}`);
  // eslint-disable-next-line no-var
  for (var i = 0; i < dateVar.length; i++) {
    dateVar[i].addEventListener('keyup', () => {
      let day = document.getElementById(`${dateField}Day`).value;
      let month = document.getElementById(`${dateField}Month`).value;
      month = month.length === 1 ? `0${month}` : month;
      day = day.length === 1 ? `0${day}` : day;
      const year = document.getElementById(`${dateField}Year`).value;
      document.getElementById(`${dateField}ValidFormat`).value =
        `${day}/${month}/${year}`;
    });
  }
});

const timeFieldsArr = ['arrival', 'departure'];

timeFieldsArr.map((timeField, i) => {
  const timeVar = document.querySelectorAll(`.${timeField}`);
  // eslint-disable-next-line no-var
  for (var i = 0; i < timeVar.length; i++) {
    timeVar[i].addEventListener('keyup', () => {
      const hour = document.getElementById(`${timeField}Hour`).value;
      const minute = document.getElementById(`${timeField}Minute`).value;
      document.getElementById(`${timeField}ValidTime`).value =
        `${hour}:${minute}:00`;
    });
  }
});

//Allow only numbers in the date and time fields
const numberFieldsIdsArr = [
  'dateOfBirthDay',
  'dateOfBirthMonth',
  'dateOfBirthYear',
  'documentExpiryDateDay',
  'documentExpiryDateMonth',
  'documentExpiryDateYear',
  'arrivalDateDay',
  'arrivalDateMonth',
  'arrivalDateYear',
  'arrivalHour',
  'arrivalMinute',
  'departureDateDay',
  'departureDateMonth',
  'departureDateYear',
  'departureHour',
  'departureMinute',
];

numberFieldsIdsArr.map((ids) => {
  if (document.getElementById(ids)) {
    document.getElementById(ids).addEventListener('keydown', (e) => {
      inputOnlyNumber(e);
    });
  }
});

function inputOnlyNumber(e) {
  if (
    isNaN(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'Tab' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Enter'
  ) {
    e.preventDefault();
  }
}
